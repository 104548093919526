import React, {
  forwardRef,
  MutableRefObject,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react"

import { useBreakpoint } from "gatsby-plugin-breakpoints"

//@ts-ignore
import * as Styles from "./Footer.module.scss"
import NavBar from "src/components/NavBar"

import InstelarFooterLogo from "src/images/revampImg/instelar_footer_logo.png"
import LinkedInIcon from "src/images/revampImg/linkedin.png"
import FbIcon from "src/images/revampImg/facebook.png"
import IgIcon from "src/images/revampImg/ig.png"
import YoutubeIcon from "src/images/revampImg/youtube.png"
import GoTopIcon from "src/images/revampImg/upArrow.png"

import { navigate } from "gatsby"

import { useTranslation } from "react-i18next"

const Footer = ({ scrollToTop }: any) => {
  const breakpoints = useBreakpoint()
  const { t } = useTranslation()

  return (
    <>
      {(breakpoints.sm && (
        <footer className={Styles.mobileFooter}>
          <div className={Styles.container}>
            <div className={Styles.top}>
              <div>{t("TXT_Social_media")}</div>
              <div className={Styles.divider}></div>
              <div className={Styles.socialMediaContainer}>
                <a
                  href="https://hk.linkedin.com/company/instelar-limited"
                  target="_blank"
                >
                  <img src={LinkedInIcon} alt="LinkedIn" />
                </a>
                <a href="https://www.facebook.com/instelarltd/" target="_blank">
                  <img src={FbIcon} alt="Facebook" />
                </a>
                <a
                  href="https://www.instagram.com/instelarltd/?hl=en"
                  target="_blank"
                >
                  <img src={IgIcon} alt="Instagram" />
                </a>
                <a href="https://www.youtube.com/@instelar" target="_blank">
                  <img src={YoutubeIcon} alt="Youtube" />
                </a>
              </div>
            </div>

            <div className={Styles.bottom}>
              {t("TXT_Copyright_text", {
                year: new Date().getFullYear(),
              })}
              <img
                src={InstelarFooterLogo}
                alt="Instelar"
                className={Styles.footerLogo}
              />
            </div>
          </div>
          <div
            className={Styles.goTopIcon}
            onClick={() => scrollToTop()}
            // onClick={() => scrollTo("#mainTop")}
          >
            <img src={GoTopIcon} alt="scroll up" />
          </div>
        </footer>
      )) || (
        <footer className={Styles.footer}>
          <div className={Styles.container}>
            <div className={Styles.left}>
              <img src={InstelarFooterLogo} alt="Instelar" />
              <div className={Styles.divider}></div>
              <div style={{ textAlign: "left" }}>
                {t("TXT_Copyright_text", {
                  year: new Date().getFullYear(),
                })}
                {/* {t("TXT_Instelar_Litmited")}
              {breakpoints.sm && <br />}
              {t("TXT_All_rights_reserved")} */}
              </div>
            </div>
            <div className={Styles.right}>
              <div>{t("TXT_Social_media")}</div>
              <div className={Styles.divider}></div>
              <div className={Styles.socialMediaContainer}>
                <a
                  href="https://hk.linkedin.com/company/instelar-limited"
                  target="_blank"
                >
                  <img src={LinkedInIcon} alt="LinkedIn" />
                </a>
                <a href="https://www.facebook.com/instelarltd/" target="_blank">
                  <img src={FbIcon} alt="Facebook" />
                </a>
                <a
                  href="https://www.instagram.com/instelarltd/?hl=en"
                  target="_blank"
                >
                  <img src={IgIcon} alt="Instagram" />
                </a>
                <a href="https://www.youtube.com/@instelar" target="_blank">
                  <img src={YoutubeIcon} alt="Youtube" />
                </a>
              </div>
            </div>
          </div>
          <div
            className={Styles.goTopIcon}
            onClick={() => scrollToTop()}
            // onClick={() => scrollTo("#mainTop")}
          >
            <img src={GoTopIcon} alt="Back to top" />
          </div>
        </footer>
      )}
    </>
  )
}

export default Footer
