// extracted by mini-css-extract-plugin
export var headerContainer = "NewNavBar-module--headerContainer--3tREO";
export var motexHeader = "NewNavBar-module--motexHeader--Ccsvq";
export var logoSection = "NewNavBar-module--logoSection--3A309";
export var instelarIcon = "NewNavBar-module--instelarIcon--101By";
export var divider = "NewNavBar-module--divider--3l6VM";
export var tabSection = "NewNavBar-module--tabSection--3dyu3";
export var toolSection = "NewNavBar-module--toolSection--12_FD";
export var loginBtn = "NewNavBar-module--loginBtn--2Lf7g";
export var wtsSection = "NewNavBar-module--wtsSection--3lEMA";
export var wtsTextSection = "NewNavBar-module--wtsTextSection--EXWyk";
export var hadBg = "NewNavBar-module--hadBg--tigTX";
export var mobileHeaderContainer = "NewNavBar-module--mobileHeaderContainer--2Ysxt";
export var expandedMenu = "NewNavBar-module--expandedMenu--1jAXV";
export var langSwitch = "NewNavBar-module--langSwitch--96WY8";
export var opt = "NewNavBar-module--opt--36iLG";
export var curr = "NewNavBar-module--curr--2twyW";
export var navSection = "NewNavBar-module--navSection--1bPHj";