import React, {
  forwardRef,
  MutableRefObject,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

//@ts-ignore
import * as Styles from "./ContactUsBtn.module.scss"
import HeadphoneIcon from "src/images/revampImg/headphone.png"
import CloseBtn from "src/images/revampImg/closeIcon.png"
import WhatsappIcon from "src/images/revampImg/whatsappInRed.png"
import { useTranslation } from "react-i18next"

const ContactUsBtn = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [modalPositionRight, setModalPositionRight] = useState(0)
  const breakpoint = useBreakpoint()
  const { t } = useTranslation()
  const modalRef = useRef(null)

  const handleWindowResize = () => {
    if (window?.innerWidth <= 1920) {
      setModalPositionRight(0)
    } else {
      const insideRight = (window?.innerWidth - 1920) / 2
      setModalPositionRight(insideRight)
    }
  }

  // set modal position
  useEffect(() => {
    handleWindowResize()
    window.addEventListener("resize", handleWindowResize)

    return () => {
      // window.removeEventListener('resize', handleWindowResize);
    }
  }, [])

  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref?.current && !ref?.current.contains(event.target)) {
          setModalOpen(false)
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside)
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside)
      }
    }, [ref])
  }

  useOutsideAlerter(modalRef)

  return (
    <div>
      {(modalOpen && (
        <div
          className={Styles.contactModal}
          ref={modalRef}
          style={{
            bottom: breakpoint.sm ? "20px" : "150px",
            right: modalPositionRight,
          }}
        >
          <div>
            <div
              style={{ cursor: "pointer", float: "right" }}
              onClick={() => setModalOpen(false)}
            >
              <img src={CloseBtn} alt="close" style={{ float: "right" }} />
            </div>

            <div className={Styles.title}>{t("TXT_Contact_us")}</div>
            <div
              style={{
                width: "100%",
                height: "1px",
                background: "white",
                margin: "10px 0",
              }}
            />
            <div className={Styles.row}>{t("TXT_Contact_modal_desc1")}</div>
            <div className={Styles.row}>{t("TXT_Contact_modal_desc2")}</div>
          </div>

          <div>
            <div className={Styles.row}>
              <div className={Styles.label}>{t("TXT_Address")}: </div>
              <div>{t("TXT_Address_text")}</div>
            </div>

            <div className={Styles.row}>
              <div className={Styles.label}> {t("TXT_Email")}: </div>
              <div>{t("info@instelar.com")}</div>
            </div>

            <div className={Styles.row}>
              <div className={Styles.label}> {t("TXT_Tel")}: </div>
              <div>{t("+852 2110 4205")}</div>
            </div>
          </div>

          <a
            className={Styles.wtsSection}
            href={"https://wa.me/85298897592"}
            target="_blank"
          >
            <img src={WhatsappIcon} alt="whatsapp us" />
            <div className={Styles.divider}></div>
            <div className={Styles.wtsTextSection}>
              <div style={{ fontSize: "18px", fontWeight: "bold" }}>
                {t("TXT_Whatsapp_us")}
              </div>
            </div>
          </a>
        </div>
      )) || (
        <div
          className={Styles.contactUsBtn}
          style={{
            bottom: breakpoint.sm ? "20px" : "150px",
            right: modalPositionRight,
          }}
          onClick={() => setModalOpen(!modalOpen)}
        >
          <img src={HeadphoneIcon} alt="Contact us" />
          <div className={Styles.divider}></div>
          <div>{t("TXT_Contact_us")}</div>
        </div>
      )}
    </div>
  )
}

export default ContactUsBtn
