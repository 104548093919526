// extracted by mini-css-extract-plugin
export var footer = "Footer-module--footer--1UIGC";
export var container = "Footer-module--container--2NkpZ";
export var left = "Footer-module--left--1d6rl";
export var divider = "Footer-module--divider--29zAU";
export var right = "Footer-module--right--2dLKt";
export var socialMediaContainer = "Footer-module--socialMediaContainer--1IO_Z";
export var goTopIcon = "Footer-module--goTopIcon--ZKHF9";
export var mobileFooter = "Footer-module--mobileFooter--2fyfy";
export var top = "Footer-module--top--RfvXY";
export var bottom = "Footer-module--bottom--1mu8X";
export var footerLogo = "Footer-module--footerLogo--k-oBX";