import React, { useEffect, useRef, useState } from "react"

import { useTranslation } from "react-i18next"
//@ts-ignore
import * as Styles from "./LangSwitcher.module.scss"
import i18next from "i18next"

const LangSwitcher = () => {
  const [selectedLang, setSelectedLang] = useState("EN")
  const [toggle, setToggle] = useState(false)
  const langListRef = useRef(null)

  const getLanguage = () => {
    return (
      i18next.language ||
      (typeof window !== "undefined" && window.localStorage.i18nextLng) ||
      "en"
    )
  }

  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event: any) {
        if (ref?.current && !ref?.current.contains(event.target)) {
          setToggle(false)
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside)
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside)
      }
    }, [ref])
  }

  useOutsideAlerter(langListRef)

  return (
    <div style={{ position: "static" }}>
      <div
        className={Styles.langSwitcher}
        onClick={() => {
          setToggle(true)
        }}
      >
        {getLanguage() == "en" ? "EN" : getLanguage() == "tc" ? "繁" : "简"}
      </div>

      {/* lang options  */}
      {toggle && (
        <div
          ref={langListRef}
          className={Styles.langOpts}
          style={{
            right:
              window?.innerWidth <= 1920 ? 40 : (window?.innerWidth - 1886) / 2,
          }}
          // onMouseLeave={() => setToggle(false)}
        >
          {[
            { text: "EN", lagnCode: "en" },
            { text: "繁", lagnCode: "tc" },
            { text: "简", lagnCode: "sc" },
          ].map((lang, i) => {
            return (
              <div
                className={Styles.opt}
                key={i}
                onClick={() => {
                  i18next.changeLanguage(lang.lagnCode)
                  localStorage.setItem("langCode", lang.lagnCode)
                  setToggle(false)
                }}
              >
                {lang.text}
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default LangSwitcher
