import React, { useEffect, useState } from "react"

import Classnames from "classnames"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"

import { AnchorLink } from "gatsby-plugin-anchor-links"

//@ts-ignore
import * as Styles from "./NewNavBar.module.scss"
import scrollTo from "gatsby-plugin-smoothscroll"
import InstelarIconWhite from "src/images/revampImg/Instelar_logo.png"
import MotexIconWhite from "src/images/revampImg/Motex_logo.png"
import LeftArrowWhite from "src/images/revampImg/LeftArrow.png"
import WhatsappIcon from "src/images/revampImg/wtsapIconWithBorder.png"
import UserIcon from "src/images/revampImg/userIcon.png"
import { useTranslation } from "react-i18next"
import LangSwitcher from "./LangSwitcher"

// mobile icon
import InstelarIconWhiteMobile from "src/images/revampImg/mobile/instelarLogoMobile.png"
import MotexIconWhiteMobile from "src/images/revampImg/mobile/motexLogoMobile.png"
import LeftArrowWhiteMobile from "src/images/revampImg/mobile/LeftArrowMobile.png"
import MenuBtn from "src/images/revampImg/mobile/mobileMenu.png"
import WhatsappIconRed from "src/images/revampImg/mobile/whatsappIconRed.png"
import CloseBtn from "src/images/revampImg/closeIcon.png"
import i18next from "i18next"
import { Link } from "gatsby"

const NewNavBar = ({ notTransparent }: { notTransparent?: boolean }) => {
  const breakpoints = useBreakpoint()
  const { t } = useTranslation()

  const [isScrollDown, setisScrollDown] = useState<boolean>(false)
  const [fillBg, setFillBg] = useState<boolean>(false)
  const [isMenuExpand, setIsMenuExpand] = useState<boolean>(false)

  useScrollPosition(({ prevPos, currPos }) => {
    setFillBg(() => currPos.y < 0)
    setisScrollDown(-currPos.y / 2 > 80)
  }, ...Array(3))

  const getLanguage = () => {
    return (
      i18next.language ||
      (typeof window !== "undefined" && window.localStorage.i18nextLng) ||
      "en"
    )
  }

  // lock html scroll when menu expand
  useEffect(() => {
    if (isMenuExpand) {
      document.getElementsByTagName("html")[0].style.overflow = "hidden"
    }

    return () => {
      document.getElementsByTagName("html")[0].style.overflow = "scroll"
    }
  }, [isMenuExpand])

  return (
    <>
      {(breakpoints.sm && (
        <div
          className={Classnames(Styles.mobileHeaderContainer, {
            [Styles.hadBg]: fillBg || isMenuExpand || notTransparent,
          })}
        >
          <header
            className={Styles.motexHeader}
            style={{ position: "relative" }}
          >
            <div className={Styles.logoSection}>
              <a
                href="https://www.instelar.com/"
                className={Styles.instelarIcon}
              >
                <img src={LeftArrowWhiteMobile} alt="to instelar" />
                <img src={InstelarIconWhiteMobile} alt="to instelar" />
              </a>
              <div className={Styles.divider}></div>

              <div style={{ height: "22.5px" }}>
                <img src={MotexIconWhiteMobile} alt="Motex" />
              </div>
            </div>
            <img
              src={isMenuExpand ? CloseBtn : MenuBtn}
              alt="expand menu"
              onClick={() => setIsMenuExpand(!isMenuExpand)}
            />
            {isMenuExpand && (
              <div className={Styles.expandedMenu}>
                <div>
                  <div className={Styles.langSwitch}>
                    {[
                      { text: "ENGLISH", lagnCode: "en" },
                      { text: "繁體中文", lagnCode: "tc" },
                      { text: "简体中文", lagnCode: "sc" },
                    ].map((lang, i) => {
                      return (
                        <div
                          className={Classnames(Styles.opt, {
                            [Styles.curr]: lang.lagnCode == getLanguage(),
                          })}
                          key={i}
                          onClick={() => {
                            i18next.changeLanguage(lang.lagnCode)
                            localStorage.setItem("langCode", lang.lagnCode)
                          }}
                        >
                          {lang.text}
                        </div>
                      )
                    })}
                  </div>
                  <nav className={Styles.navSection}>
                    <AnchorLink
                      to="/"
                      title="Home"
                      stripHash
                      onAnchorLinkClick={() => setIsMenuExpand(false)}
                    >
                      {t("TXT_Homepage")}
                    </AnchorLink>

                    {/* <a onClick={() => scrollTo("#blueprintManagement")}>
                      {t("TXT_Blueprint_management")}
                    </a> */}

                    <AnchorLink
                      to="/#blueprintManagement"
                      title="Blueprint management"
                      stripHash
                      onAnchorLinkClick={() => {
                        setIsMenuExpand(false)
                        // addPaddingForFirstRow()
                      }}
                    >
                      {t("TXT_Blueprint_management")}
                    </AnchorLink>

                    <AnchorLink
                      to="/#missionManagement"
                      title="Mission assignment"
                      stripHash
                      onAnchorLinkClick={() => setIsMenuExpand(false)}
                    >
                      {t("TXT_Mission_assign")}
                    </AnchorLink>

                    <AnchorLink
                      to="/#installationManagement"
                      title="Installation managemant"
                      stripHash
                      onAnchorLinkClick={() => setIsMenuExpand(false)}
                    >
                      {t("TXT_Installation_managemant")}
                    </AnchorLink>

                    <AnchorLink
                      to="/#assetsManagement"
                      title="Assest management"
                      stripHash
                      onAnchorLinkClick={() => setIsMenuExpand(false)}
                    >
                      {t("TXT_Assets_management")}
                    </AnchorLink>

                    <AnchorLink
                      to="/#logisticsTracking"
                      title="Logistics tracking"
                      stripHash
                      onAnchorLinkClick={() => setIsMenuExpand(false)}
                    >
                      {t("TXT_Logistic_tracking")}
                    </AnchorLink>

                    <Link to="/blog" title="Blog">
                      {t("TXT_Web_blog")}
                    </Link>
                    {/* <AnchorLink
                      to="/#motexBlog"
                      title="Blog"
                      stripHash
                      onAnchorLinkClick={() => setIsMenuExpand(false)}
                    >
                      {t("TXT_Web_blog")}
                    </AnchorLink> */}
                  </nav>

                  <a
                    href="https://app.motex.io/"
                    target="_blank"
                    className={Styles.loginBtn}
                  >
                    {t("TXT_Login")}
                  </a>

                  <a
                    className={Styles.wtsSection}
                    href={"https://wa.me/85298897592"}
                    target="_blank"
                  >
                    <img src={WhatsappIconRed} alt="whatsapp us" />
                    <div className={Styles.divider}></div>
                    <div className={Styles.wtsTextSection}>
                      <div style={{ fontSize: "12px" }}>
                        {t("TXT_Need_help")}
                      </div>
                      <div style={{ fontSize: "18px", fontWeight: "bold" }}>
                        {t("TXT_Whatsapp_us")}
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            )}
          </header>
        </div>
      )) || (
        <div
          className={Classnames(Styles.headerContainer, {
            [Styles.hadBg]: fillBg || notTransparent,
          })}
        >
          <header className={Styles.motexHeader}>
            <div className={Styles.logoSection}>
              <a
                href="https://www.instelar.com/"
                className={Styles.instelarIcon}
              >
                <img src={LeftArrowWhite} alt="to instelar" />
                <img src={InstelarIconWhite} alt="to instelar" />
              </a>
              <div className={Styles.divider}></div>

              <div style={{ height: "45px" }}>
                <img src={MotexIconWhite} alt="Motex" />
              </div>
            </div>

            <nav className={Styles.tabSection}>
              <AnchorLink
                to="/#blueprintManagement"
                title="Blueprint management"
                stripHash
              >
                {t("TXT_Blueprint_management")}
              </AnchorLink>

              <AnchorLink
                to="/#missionManagement"
                title="Mission assignment"
                stripHash
              >
                {t("TXT_Mission_assign")}
              </AnchorLink>

              <AnchorLink
                to="/#installationManagement"
                title="Installation managemant"
                stripHash
              >
                {t("TXT_Installation_managemant")}
              </AnchorLink>

              <AnchorLink
                to="/#assetsManagement"
                title="Assest management"
                stripHash
              >
                {t("TXT_Assets_management")}
              </AnchorLink>

              <AnchorLink
                to="/#logisticsTracking"
                title="Logistics tracking"
                stripHash
              >
                {t("TXT_Logistic_tracking")}
              </AnchorLink>

              <Link to="/blog" title="Blog">
                {t("TXT_Web_blog")}
              </Link>
            </nav>

            <div className={Styles.toolSection}>
              <a
                href="https://app.motex.io/"
                target="_blank"
                className={Styles.loginBtn}
              >
                <img src={UserIcon} alt="login" />
                <div className={Styles.divider}></div>
                {t("TXT_Login")}
              </a>
              <a
                className={Styles.wtsSection}
                href={"https://wa.me/85298897592"}
                target="_blank"
              >
                <img
                  src={WhatsappIcon}
                  alt="whatsapp us"
                  width="50px"
                  height="50px"
                />
                {/* <div className={Styles.divider}></div>
                <div className={Styles.wtsTextSection}>
                  <div style={{ fontSize: "12px" }}>{t("TXT_Need_help")}</div>
                  <div style={{ fontSize: "18px", fontWeight: "bold" }}>
                    {t("TXT_Whatsapp_us")}
                  </div>
                </div> */}
              </a>
              <LangSwitcher />
            </div>
          </header>
        </div>
      )}
    </>
  )
}

export default NewNavBar
